<script>

import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        fields: function () {
            return [
                {
                    key: "code",
                    label: this.$t('invoices::invoices.columns.code').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "target_id",
                    label: this.$t('invoices::invoices.relationships.target').ucFirst(),
                    formatter: (value, index, item) => item.target?.name || value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "type_id",
                    label: this.$t('invoices::invoices.relationships.user').ucFirst(),
                    formatter: (value, index, item) => item.type ? (item.type?.translation_key ? this.$t(item.type.translation_key).ucFirst() : item.type?.name) : value,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "file",
                    label: this.$t('invoices::invoices.relationships.file').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "fee_requester_id",
                    label: this.$t('invoices::invoices.columns.fee_requester_id').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "settled_at",
                    label: this.$t('invoices::invoices.columns.settled_at').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "canceled_at",
                    label: this.$t('invoices::invoices.columns.canceled_at').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "sent_at",
                    label: this.$t('invoices::invoices.columns.sent_at').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {};
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'invoices::invoices.invoice', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }

    // :create-link="{ name: 'my-invoice-create' }"
};
</script>

<template>
    <advanced-table
        :caption="$tc('invoices::invoices.invoice', 2).ucFirst()"
        :current-page="1"
        :edit-link="{ name: 'my-invoice-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :queries="{ with: ['target', 'type'] }"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        moduleNamespace="invoice"
        action="own"
        modulePager="ownPager"
        outlined
        permission="invoices"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
    >
    </advanced-table>
</template>
